@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?me284n');
  src:  url('fonts/icomoon.eot?me284n#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?me284n') format('truetype'),
    url('fonts/icomoon.woff?me284n') format('woff'),
    url('fonts/icomoon.svg?me284n#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-calendar_today-24px:before {
  content: "\e968";
}
.icon-people_alt-24px:before {
  content: "\e967";
}
.icon-king_bed-24px:before {
  content: "\e966";
}
.icon-emoji_transportation-24px:before {
  content: "\e964";
}
.icon-emoji_food_beverage-24px:before {
  content: "\e965";
}
.icon-work_outline-24px:before {
  content: "\e962";
}
.icon-list_alt-24px:before {
  content: "\e963";
}
.icon-file_copy-24px:before {
  content: "\e961";
}
.icon-notes-24px:before {
  content: "\e960";
}
.icon-add_comment-24px:before {
  content: "\e95f";
}
.icon-bookmarks-24px:before {
  content: "\e95e";
}
.icon-accessibility_new-24px:before {
  content: "\e95d";
}
.icon-trip_origin-24px:before {
  content: "\e90c";
}
.icon-map-24px:before {
  content: "\e90d";
}
.icon-local_offer-24px:before {
  content: "\e90e";
}
.icon-local_library-24px:before {
  content: "\e90f";
}
.icon-check_box-24px:before {
  content: "\e910";
}
.icon-radio_button_unchecked-24px:before {
  content: "\e911";
}
.icon-radio_button_checked-24px:before {
  content: "\e912";
}
.icon-mode_comment-24px:before {
  content: "\e913";
}
.icon-wifi_tethering-24px:before {
  content: "\e914";
}
.icon-remove_circle_outline-24px:before {
  content: "\e915";
}
.icon-how_to_reg-24px:before {
  content: "\e916";
}
.icon-clear-24px:before {
  content: "\e917";
}
.icon-add_circle_outline-24px:before {
  content: "\e918";
}
.icon-sentiment_satisfied_alt-24px:before {
  content: "\e919";
}
.icon-call-24px:before {
  content: "\e91a";
}
.icon-videocam-24px:before {
  content: "\e91b";
}
.icon-play_arrow-24px:before {
  content: "\e91c";
}
.icon-error-24px:before {
  content: "\e91d";
}
.icon-search-24px:before {
  content: "\e91e";
}
.icon-reorder-24px:before {
  content: "\e91f";
}
.icon-done_outline-24px:before {
  content: "\e920";
}
.icon-contact_support-24px:before {
  content: "\e921";
}
.icon-change_history-24px:before {
  content: "\e922";
}
.icon-bookmark_border-24px:before {
  content: "\e923";
}
.icon-bookmark-24px:before {
  content: "\e924";
}
.icon-assignment-24px:before {
  content: "\e925";
}
.icon-announcement-24px:before {
  content: "\e926";
}
.icon-settings_applications-24px:before {
  content: "\e927";
}
.icon-all_inclusive-24px:before {
  content: "\e928";
}
.icon-ac_unit-24px:before {
  content: "\e929";
}
.icon-drive_eta-24px:before {
  content: "\e92a";
}
.icon-cancel-24px:before {
  content: "\e92b";
}
.icon-apps-24px:before {
  content: "\e92c";
}
.icon-local_dining-24px:before {
  content: "\e92d";
}
.icon-local_atm-24px:before {
  content: "\e92e";
}
.icon-local_cafe-24px:before {
  content: "\e92f";
}
.icon-local_bar-24px:before {
  content: "\e930";
}
.icon-flight-24px:before {
  content: "\e931";
}
.icon-directions_car-24px:before {
  content: "\e932";
}
.icon-directions_bus-24px:before {
  content: "\e933";
}
.icon-directions_bike-24px:before {
  content: "\e934";
}
.icon-collections-24px:before {
  content: "\e935";
}
.icon-adjust-24px:before {
  content: "\e936";
}
.icon-computer-24px:before {
  content: "\e937";
}
.icon-cloud-24px:before {
  content: "\e938";
}
.icon-bubble_chart-24px:before {
  content: "\e939";
}
.icon-airplanemode_active-24px:before {
  content: "\e93a";
}
.icon-textsms-24px:before {
  content: "\e93b";
}
.icon-message-24px:before {
  content: "\e93c";
}
.icon-forum-24px:before {
  content: "\e93d";
}
.icon-import_contacts-24px:before {
  content: "\e93e";
}
.icon-email-24px:before {
  content: "\e93f";
}
.icon-comment-24px:before {
  content: "\e940";
}
.icon-chat-24px:before {
  content: "\e941";
}
.icon-warning-24px:before {
  content: "\e942";
}
.icon-visibility-24px:before {
  content: "\e943";
}
.icon-subject-24px:before {
  content: "\e944";
}
.icon-speaker_notes-24px:before {
  content: "\e945";
}
.icon-room-24px:before {
  content: "\e946";
}
.icon-schedule-24px:before {
  content: "\e947";
}
.icon-receipt-24px:before {
  content: "\e948";
}
.icon-pan_tool-24px:before {
  content: "\e949";
}
.icon-pageview-24px:before {
  content: "\e94a";
}
.icon-info-24px:before {
  content: "\e94b";
}
.icon-language-24px:before {
  content: "\e94c";
}
.icon-home-24px:before {
  content: "\e94d";
}
.icon-help_outline-24px:before {
  content: "\e94e";
}
.icon-grade-24px:before {
  content: "\e94f";
}
.icon-feedback-24px:before {
  content: "\e950";
}
.icon-face-24px:before {
  content: "\e951";
}
.icon-event-24px:before {
  content: "\e952";
}
.icon-date_range-24px:before {
  content: "\e953";
}
.icon-commute-24px:before {
  content: "\e954";
}
.icon-class-24px:before {
  content: "\e955";
}
.icon-card_giftcard-24px:before {
  content: "\e956";
}
.icon-build-24px:before {
  content: "\e957";
}
.icon-bug_report-24px:before {
  content: "\e958";
}
.icon-share-24px:before {
  content: "\e959";
}
.icon-school-24px:before {
  content: "\e95a";
}
.icon-account_circle-24px:before {
  content: "\e95b";
}
.icon-more_horiz-24px:before {
  content: "\e95c";
}
.icon-insta-24px:before {
  content: "\e90b";
}
.icon-percent-24px:before {
  content: "\e900";
}
.icon-coupon-24px:before {
  content: "\e901";
}
.icon-feefo-24px:before {
  content: "\e902";
}
.icon-here-24px:before {
  content: "\e903";
}
.icon-tripadvisor-24px:before {
  content: "\e904";
}
.icon-pinterest-24px:before {
  content: "\e905";
}
.icon-linkedin-24px:before {
  content: "\e906";
}
.icon-twitter-24px:before {
  content: "\e907";
}
.icon-facebook-24px:before {
  content: "\e908";
}
.icon-guestbook-24px:before {
  content: "\e909";
}
.icon-callnow-24px:before {
  content: "\e90a";
}
.icon-images:before {
  content: "\e969";
}
.icon-book1:before {
  content: "\e96a";
}
.icon-books1:before {
  content: "\e96b";
}
.icon-stack:before {
  content: "\e96c";
}
.icon-location2:before {
  content: "\e96d";
}
.icon-map:before {
  content: "\e96e";
}
.icon-user-plus:before {
  content: "\e973";
}
.icon-key2:before {
  content: "\e98e";
}
.icon-lock:before {
  content: "\e98f";
}
.icon-warning:before {
  content: "\ea07";
}
.icon-notification:before {
  content: "\ea08";
}
.icon-checkbox-unchecked:before {
  content: "\ea53";
}
.icon-radio-checked:before {
  content: "\ea54";
}
.icon-facebook2:before {
  content: "\ea91";
}
.icon-whatsapp:before {
  content: "\ea93";
}
.icon-youtube:before {
  content: "\ea9d";
}
.icon-vimeo2:before {
  content: "\eaa1";
}
.icon-onedrive:before {
  content: "\eaaf";
}
.icon-linkedin:before {
  content: "\eac9";
}
.icon-stackoverflow:before {
  content: "\ead0";
}
.icon-pinterest:before {
  content: "\ead1";
}
.icon-file-pdf:before {
  content: "\eadf";
}
.icon-book:before {
  content: "\e96f";
}
.icon-books:before {
  content: "\e970";
}
.icon-address-book:before {
  content: "\e971";
}
.icon-clock:before {
  content: "\e972";
}
.icon-calendar:before {
  content: "\e974";
}
.icon-bubbles3:before {
  content: "\e975";
}
.icon-user-tie:before {
  content: "\e976";
}
.icon-key:before {
  content: "\e98d";
}
.icon-cog:before {
  content: "\e994";
}
.icon-sphere:before {
  content: "\e9c9";
}
.icon-link:before {
  content: "\e9cb";
}
.icon-star-empty:before {
  content: "\e9d7";
}
.icon-hangouts:before {
  content: "\ea8e";
}
